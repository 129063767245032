import Link from 'next/link'
/* Images */
import Image from 'next/future/image';
import codiceologo from '../assets/imgs/logos/codiceo.svg';
import facebook from '../assets/imgs/icons/facebook-blue.svg';
import twitter from '../assets/imgs/icons/twitter-blue.svg';
import linkedin from '../assets/imgs/icons/linkedin-blue.svg';
import CookiesConsent from './cookies_consent';
import { useEffect, useRef } from 'react';

const footer = () => {
    useEffect(() => {
        window.$crisp = [];
        window.CRISP_WEBSITE_ID = "8da96aed-924e-42d6-a3ba-8cd80aa74029";

        (function () {
            const d = document;
            const s = d.createElement("script");
            s.rel = "dns-prefetch"
            s.src = "https://client.crisp.chat/l.js";
            s.async = 1;
            d.getElementsByTagName("head")[0].appendChild(s);
        })()
    })
    return (
        <>
            <CookiesConsent />
            <footer className="py-10 lg:py-20">
                <div className="container px-4 mx-auto">
                    <div className="flex flex-wrap mb-12 lg:mb-20 -mx-3 text-center lg:text-left">
                        <div className="w-full lg:w-1/5 px-3 mb-6 lg:mb-0">
                            <Link href="/">
                                <a className="flex w-full justify-center mx-auto lg:mx-0 text-3xl font-semibold leading-none" style={{ marginTop: "9px" }}>
                                    <Image src={codiceologo} alt="Codiceo" style={{ width: "10rem" }}/>
                                </a>
                            </Link>
                        </div>
                        <div className="w-full lg:w-2/5 px-3 mb-8 lg:mb-0">
                            <p className="max-w-md mx-auto lg:max-w-full lg:mx-0 lg:pr-32 lg:text-lg text-blueGray-400 leading-relaxed">
                                Commençons à <strong>développer</strong> votre futur digital ensemble
                            </p>
                        </div>
                        <div className="w-full lg:w-1/5 px-3 mb-8 lg:mb-0">
                            <p className="mb-2 lg:mb-4 lg:text-lg font-bold font-heading text-blueGray-800">Où sommes-nous ?</p>
                            <p className="lg:text-lg text-blueGray-400">289 rue Garibaldi, 69007 Lyon</p>
                        </div>
                        <div className="w-full lg:w-1/5 px-3">
                            <p className="mb-2 lg:mb-4 lg:text-lg font-bold font-heading text-blueGray-800">Contact</p>
                            <p className="lg:text-lg text-blueGray-400">04 78 96 93 35</p>
                            <p className="lg:text-lg text-blueGray-400"><a href="mailto:contact@codiceo.fr">contact@codiceo.fr</a></p>
                        </div>
                    </div>
                    <div className="flex flex-col lg:flex-row items-center lg:justify-between">
                        <p className="text-sm text-blueGray-400">&copy; 2021. Tous droits réservés | <Link href="/legal-notices"><a>Mentions légales</a></Link></p>
                        <div className="flex items-center justify-start order-first lg:order-last -mx-2 mb-4 lg:mb-0">
                            <a className="inline-block px-2" href="https://www.facebook.com/profile.php?id=100073462324202" target="_blank" rel="noreferrer">
                                <Image src={facebook} alt="Facebook" style={{ width: "1.25rem", height: "1.25rem" }}/>
                            </a>
                            <a className="inline-block px-2" href="https://twitter.com/wearecodiceo" target="_blank" rel="noreferrer">
                                <Image src={twitter} alt="Twitter" style={{ width: "2rem", height: "2rem" }}/>
                            </a>
                            <a className="inline-block px-2" href="https://www.linkedin.com/company/codiceo" target="_blank" rel="noreferrer">
                                <Image src={linkedin} alt="Linkedin" style={{ width: "1.5rem", height: "1.5rem" }}/>
                            </a>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default footer;