import { useEffect } from 'react';
import { getCookies, setCookie } from 'cookies-next';
import Link from 'next/link';

const cookiesConsent = () => {
    useEffect(() => {
        start()
    });
    return (
        <div className="cookie-consent" id="cookie-consent">
            <span>Ce site utilise des cookies</span>
            <p>
                Nous utilisons des cookies pour assurer le bon fonctionnement du site, améliorer votre expérience utilisateur et faciliter votre navigation.
                <Link href="/cookies"><a id="link_p" style={{ color: "#5da5d8" }}>Consultez notre politique d’utilisation des cookies</a></Link>
            </p>

            <div className="consent-buttons" style={{ paddingTop: "8px" }}>
                <button onClick={refuseCookies}>Je refuse</button>
                <button className="btn-blue" onClick={acceptCookies} >J'accepte</button>
            </div>
        </div>

    );
}

//acceptCookies
function acceptCookies() {
    setCookie('CookieConsent', 'true', { maxAge: 60 * 60 * 24 * 7 * 30 * 25, sameSite: 'lax' })
    hideCookies();
}

//refuseCookies
function refuseCookies() {
    setCookie('CookieConsent', 'false', { maxAge: 60 * 60 * 24 * 7 * 30 * 25, sameSite: 'lax' })
    hideCookies();
}

//get CookieConsent
function getCookieConsent() {
    let split = document.cookie.split(';')
    let result = split[0]
    return getCookies(result, 'CookieConsent') //switch to expires for know MaxAge date
}

//hide Cookies
function hideCookies() {
    document.getElementById('cookie-consent').style.display = 'none';
}

//show Cookies
function showCookies() {
    document.getElementById('cookie-consent').style.display = 'block';
}

//check if cookies are present
function start() {
    let split = document.cookie
    if (!split.match("CookieConsent")) {
        showCookies();
    } else {
        hideCookies();
    }
}

export default cookiesConsent;