import React from 'react';

/* Images */
import Image from 'next/future/image';
import codiceologo from '../assets/imgs/logos/codiceo.svg';
import facebook from '../assets/imgs/icons/facebook-blue.svg';
import twitter from '../assets/imgs/icons/twitter-blue.svg';
import linkedin from '../assets/imgs/icons/linkedin-in-brands.svg';

/*Link*/
import Link from 'next/link';
import { useState } from 'react';

/*menu_mobile*/
import Modal from 'react-modal';
/*Icon */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBars, faEnvelope
} from "@fortawesome/free-solid-svg-icons";


const header = () => {
    Modal.setAppElement('main');
    const [modalIsOpen, setIsOpen] = useState(false);
    function openModal() {
        setIsOpen(true);
    }
    function closeModal() {
        setIsOpen(false);
    }

    const [PlusorCross, setPlusorCross] = useState(false)

    const handleScrollNav = () => {
        const body = document.querySelector('body');
        if (body)
            body.classList.toggle('sticky-nav', window.scrollY > 0);
    }

    React.useEffect(() => {
        window.addEventListener('scroll', handleScrollNav);

        return () => {
            window.removeEventListener('scroll', handleScrollNav);
        }
    })

    return (
        <>
            <header id="navbar" className="bg-transparent pt-4 z-20">
                <div className="container px-4 mx-auto bg-transparent">
                    <nav className="bg-transparent flex justify-between items-center py-3 pl-12 sm:pl-16 text-center">
                        <div className="w-full sm:w-max flex justify-center">
                            <Link href="/">
                                <a className="text-3xl font-semibold leading-none">
                                    <Image src={codiceologo} alt="Codiceo" width={300} height={37} className="w-40" style={{ margin: "0 3rem 0 0" }} />
                                </a>
                            </Link>
                        </div>
                        <div className="flex justify-end items-center">
                            <ul className="hidden lg:flex lg:items-center lg:w-auto lg:space-x-12">
                                <li className="group relative pt-4 pb-4">
                                    <Link href="/"><a className="text-sm font-semibold text-blueGray-600 hover:text-blueGray-500">Accueil</a></Link>
                                </li>
                                <li className="pt-4 pb-4">
                                    <Link href="/about"><a className="text-sm font-semibold text-blueGray-600 hover:text-blueGray-500">À propos</a></Link>
                                </li>
                                <li id="btnservices" className="group relative pt-4 pb-4 has-child">
                                    <a href='#' className="text-sm font-semibold text-blueGray-600 hover:text-blueGray-500 cursor-pointer">Services</a>
                                    <ul className="absolute left-0 bg-white rounded-lg shadow-sm w-full opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-500 min-w-200 pt-4 pb-4 border border-gray-100">
                                        <li>
                                            <Link href="/services/developpement-logiciel"><a className="menu-sub-item text-sm text-blueGray-600 hover:text-blueGray-500 text-left">Développement logiciel sur mesure & Application mobile</a></Link>
                                        </li>
                                        <li>
                                            <Link href="/services/developpement-site-internet"><a className="menu-sub-item text-sm text-blueGray-600 hover:text-blueGray-500 text-left">Création site Internet & Référencement</a></Link>
                                        </li>
                                    </ul>
                                </li>
                                <li id="btnoutils" className="group relative pt-4 pb-4 has-child">
                                    <Link href="#"><a className="text-sm font-semibold text-blueGray-600 hover:text-blueGray-500">Outils</a></Link>
                                    <ul className="absolute left-0 bg-white rounded-lg shadow-sm w-full opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-500 min-w-200 pt-4 pb-4 border border-gray-100">
                                        <li>
                                            <a href="/codiceo-insights/" target="_blank" className="menu-sub-item text-sm text-blueGray-600 hover:text-blueGray-500"><b>Codiceo Insights</b><br />Consulter les performances de votre site web</a>
                                        </li>
                                    </ul>
                                </li>
                                <li className="group relative pt-4 pb-4">
                                    <Link href="/portfolio"><a className="text-sm font-semibold text-blueGray-600 hover:text-blueGray-500">Réalisations</a></Link>
                                </li>
                                <li className="pt-4 pb-4">
                                    <Link href="/blog"><a className="text-sm font-semibold text-blueGray-600 hover:text-blueGray-500">Blog</a></Link>
                                </li>
                            </ul>
                            <div className="hidden lg:block" style={{ margin: "0 0 0 2.5rem" }}>
                                <Link href="/contact"><a className="hidden tracking-wide hover-up-2 xl:inline-block px-4 py-3 text-xs font-semibold leading-none bg-green-300 hover:bg-green-700 text-white rounded-full">Nous contacter</a></Link>
                                <Link href="/contact">
                                    <button type="button" aria-label="Nous contacter" className="xl:hidden tracking-wide hover-up-2 inline-block px-4 py-3 text-xs font-semibold leading-none bg-green-300 hover:bg-green-700 text-white rounded-full">
                                        <FontAwesomeIcon icon={faEnvelope} width={16} height={16} />
                                    </button>
                                </Link>
                            </div>
                        </div>
                        <div className="lg:hidden">
                            <button type="button" className="flex items-center py-2 px-3 rounded" aria-label="side menu" onClick={openModal}>
                                <FontAwesomeIcon icon={faBars} width={16} height={16} color="#3B82F6" />
                            </button>
                        </div>
                    </nav>
                </div>
            </header>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                overlayClassName="navbar-menu relative z-50 transition duration-300">
                <div className="navbar-close fixed inset-0 bg-blueGray-800 opacity-25" onClick={closeModal}></div>
                <nav className="fixed top-0 left-0 bottom-0 flex flex-col w-5/6 max-w-sm py-6 px-6 bg-white border-r overflow-y-auto transition duration-300">
                    <div className="flex items-center mb-8">
                        <Link className="mr-auto text-3xl font-semibold leading-none" href="/">
                            <a className='flex h-83' style={{ height: '83%' }}>
                                <Image className="h-5" src={codiceologo} alt="Codiceo" width={300} height={20} onClick={closeModal} />
                            </a>
                        </Link>
                        <button className="navbar-close text-blueGray-400 cursor-pointer hover:text-blue-500" type="button" onClick={closeModal}>
                            <svg className="h-6 w-6 text-blueGray-400 cursor-pointer hover:text-blue-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                            </svg>
                        </button>
                    </div>
                    <div>
                        <ul className="mobile-menu">
                            <li className="mb-1 menu-item-has-children rounded-xl" onClick={closeModal}>
                                <Link href="/"><a className="block p-4 text-sm text-blueGray-500 hover:bg-blue-50 hover:text-blue-500 rounded-xl">Accueil</a></Link>
                            </li>
                            <li className="mb-1 rounded-xl" onClick={closeModal}>
                                <Link href="/about"><a className="block p-4 text-sm text-blueGray-500 hover:bg-blue-50 hover:text-blue-500 rounded-xl">À propos de nous</a></Link>
                            </li>
                            <li className="mb-1 menu-item-has-children rounded-xl">
                                <HandleServicesIcon onClick={() => { setPlusorCross(PlusorCross = !PlusorCross) }} props={PlusorCross} />
                                <a className="block p-4 text-sm text-blueGray-500 hover:bg-blue-50 hover:text-blue-500 rounded-xl" href="#" onClick={() => { setPlusorCross(PlusorCross = !PlusorCross) }}>Services</a>
                                <HandleServices props={PlusorCross} />
                            </li>
                            <li className="mb-1 rounded-xl" onClick={closeModal}>
                                <Link href="/portfolio"><a className="block p-4 text-sm text-blueGray-500 hover:bg-blue-50 hover:text-blue-500 rounded-xl">Réalisations</a></Link>
                            </li>
                            <li className="mb-1 rounded-xl" onClick={closeModal}>
                                <Link href="/blog"><a className="block p-4 text-sm text-blueGray-500 hover:bg-blue-50 hover:text-blue-500 rounded-xl">Blog</a></Link>
                            </li>
                        </ul>
                        <div className="mt-4 pt-6 border-t border-blueGray-100" onClick={closeModal}>
                            <Link href="/contact"><a className="block px-4 py-3 mb-3 text-xs text-center font-semibold leading-none bg-green-300 hover:bg-green-700 text-white rounded-full">Nous contacter</a></Link>
                        </div>
                    </div>
                    <div className="mt-auto">
                        <p className="my-4 text-xs text-blueGray-400">
                            <span>Nous contacter </span>
                            <Link href="mailto:contact@codiceo.fr"><a className="no-underline">contact@codiceo.fr</a></Link>
                        </p>
                        <div className="flex justify-start items-center">
                            <Link className="inline-block px-1" href="https://www.facebook.com/profile.php?id=100073462324202">
                                <a>
                                    <Image src={facebook} alt="Facebook" style={{ width: "1.25rem", height: "1.25rem" }} />
                                </a>
                            </Link>
                            <Link className="inline-block px-1" href="https://twitter.com/wearecodiceo">
                                <a>
                                    <Image src={twitter} alt="Twitter" className="ml-2" style={{ width: "2rem", height: "2rem" }} />
                                </a>
                            </Link>
                            <Link className="inline-block px-1" href="https://www.linkedin.com/company/codiceo">
                                <a>
                                    <Image src={linkedin} alt="Linkedin" className="ml-2" style={{ width: "1.5rem", height: "1.5rem" }} />
                                </a>
                            </Link>
                        </div>
                    </div>
                </nav>
            </Modal>
        </>
    );
}

const HandleServices = (props) => {
    if (props.props) {
        return (
            <ul className="dropdown pl-5" >
                <li className="rounded-xl">
                    <Link href="/services/developpement-logiciel"><a className="block p-3 text-sm text-blueGray-500 hover:bg-blue-50 hover:text-blue-500 rounded-xl">Développement logiciel sur mesure & Application mobile</a></Link>
                </li>
                <li className="rounded-xl">
                    <Link href="/services/developpement-site-internet"><a className="block p-3 text-sm text-blueGray-500 hover:bg-blue-50 hover:text-blue-500 rounded-xl">Création site Internet & Référencement</a></Link>
                </li>
            </ul>
        )
    }
    return (
        <div style={{ display: "none" }}></div>
    )

}

const HandleServicesIcon = (props) => {
    if (props.props) {
        return (
            <span className="menu-expand" style={{ transform: "rotate(45deg)" }}>+</span>
        )
    }
    return (
        <span className="menu-expand">+</span>
    )

}


export default header;