import Layout from '../components/layout'

import '../assets/css/main.css';
/* Style */
import 'animate.css'
import '../assets/css/cookies_consent.css';

/* Icon */
import "@fortawesome/fontawesome-svg-core/styles.css";
import { config } from "@fortawesome/fontawesome-svg-core";
config.autoAddCss = false; // Tell Font Awesome to skip adding the CSS automatically since it's being imported above
import { useEffect } from 'react';

const handleScrollNav = () => {
    const header = document.querySelector('header');
    if (header)
        header.classList.toggle('stick', window.scrollY > 20);
}

const _app = ({ Component, pageProps }) => {
    useEffect(() => {
        window.addEventListener('scroll', handleScrollNav);

        return () => {
            window.removeEventListener('scroll', handleScrollNav);
        }
    })
    return (
        <Layout>
          <Component {...pageProps} />
        </Layout>
    )
}
export default _app;