// components/layout.js

import { useRouter } from "next/router";
import Header from './header'
import Footer from './footer'
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Layout({ children }) {
  const router = useRouter();
  return (
    <>
      {(router.pathname != "/services/developpement-logiciel" && router.pathname != "/services/developpement-site-internet") && <Header />}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
        theme="colored"
        transition={Slide}
      />
      <main>{children}</main>
      <Footer />
    </>
  )
}